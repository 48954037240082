import { JSX, Show } from "solid-js";
import { App } from "./App";
import { Button } from "./common/uiKit/Button";
import { Spinner } from "./common/uiKit/Spinner";

export function MainLayout(props: { app: App; children?: JSX.Element }) {
  return (
    <>
      <Show when={props.app.seerlinqApi.loggedIn}>
        <div class="logo">
          <a href="https://seerlinq.com">
            <img src="/static/imgs/sq_logo.png" />
          </a>
        </div>
        <h1>Seerlinq CLV (C'est La Vie)</h1>
        <Show when={props.app.loading}>
          <div style="margin: 0 auto; display: flex; justify-content: center">
            <Spinner />
          </div>
        </Show>

        <Show when={!props.app.loading}>
          <header>
            <h2>Heya, {props.app.seerlinqApi.username}!</h2>
          </header>
          <aside>
            <div>
              <ul class="nav">
                <li>
                  <a href="/">List of Patients</a>
                </li>
                <Show when={props.app.seerlinqApi.canIAddPatients}>
                  <li>
                    <a href="/add-patient">Add new Patient</a>
                  </li>
                </Show>
                <Show when={props.app.seerlinqApi.amIMonitoringUser}>
                  <li>
                    <a href="/waiting-room">Waiting room</a>
                  </li>
                </Show>
                <Show when={props.app.seerlinqApi.userLevel >= 3}>
                  <li>
                    <a href="/admin">Admin</a>
                  </li>
                </Show>
                <li>
                  <i
                    class={props.app.darkMode ? "fas fa-sun" : "far fa-moon"}
                    onClick={() => {
                      props.app.toggleDarkMode();
                    }}
                  ></i>
                </li>
                <li>
                  <Button
                    onClick={() => {
                      props.app.seerlinqApi.logout();
                    }}
                  >
                    Logout
                  </Button>
                </li>
              </ul>
            </div>
          </aside>

          <main>{props.children}</main>
        </Show>

        <footer>
          <p>
            CLV version: <em>{props.app.clvVersion}</em>
            <br />
            API environment:{" "}
            <em>
              {props.app.seerlinqApi.apiInfo
                ? props.app.seerlinqApi.apiInfo.environment
                : ""}
            </em>{" "}
            | API version:{" "}
            <em>
              {props.app.seerlinqApi.apiInfo
                ? props.app.seerlinqApi.apiInfo.version
                : ""}
            </em>
            <br />
            <br />
            Copyright &copy; {new Date().getFullYear()} Seerlinq
          </p>
        </footer>
      </Show>
    </>
  );
}
