import { RequestOptions } from "@hey-api/client-fetch";
import {
  apiGetAuthLogout,
  apiGetAuthRefresh,
  apiGetInfo,
  apiGetLimiters,
  apiGetMonitoringTeams,
  apiGetUsersMe,
  APIInfoResponse,
  apiPostAuthLogin,
  apiPutPatientsPatientIdApproveConsentPaper,
  apiPutPatientsPatientIdApproveConsentTelemonitoring,
  apiPutPatientsPatientIdRevokeConsentPaper,
  apiPutPatientsPatientIdRevokeConsentTelemonitoring,
  client,
  DefaultMedicationSchemaResponse,
  FullUserResponse,
  Level3PatientResponse,
  PatientResponse,
  UserPatientRoleResponse,
  UserResponseManaging,
} from ".";
import { apiRoute } from "../common/config";
import { userLevel } from "../common/roles";

// basic settings
const rolesCanAddPatients = [
  "physician",
  "study-physician",
  "seerlinq-user",
  "admin",
];

export class ApiConnector {
  apiRoute: string;
  apiVersion: string;
  loggedIn: boolean;
  username: string | null;
  userUUID: string | null;
  userLevel: number | null;
  apiInfo: APIInfoResponse;
  canIAddPatients: boolean;
  isProdEnv: boolean;
  amIMonitoringUser: boolean;

  private refreshPromise?: Promise<boolean>;
  private preClonedRequests = new Map<Request, Request>();

  constructor(apiRoute: string, apiVersion: string = "v1") {
    this.apiRoute = apiRoute;
    this.apiVersion = apiVersion;
    this.loggedIn = false;
    this.username = null;
    this.apiInfo = null;
    this.canIAddPatients = false;
    this.isProdEnv = false;
    this.amIMonitoringUser = false;
    console.log("Initialized with route", apiRoute);
  }

  init() {
    client.setConfig({
      baseUrl: apiRoute,
      credentials: "include",
    });

    client.interceptors.request.use(this.interceptRequest);
    client.interceptors.response.use(this.interceptResponse);
    client.interceptors.error.use(this.interceptError);
  }

  destroy() {
    client.interceptors.request.eject(this.interceptRequest);
    client.interceptors.response.eject(this.interceptResponse);
    client.interceptors.error.eject(this.interceptError);
  }

  versionedRoute() {
    return `${this.apiRoute}/api/${this.apiVersion}`;
  }

  // headers

  private refreshHeaders() {
    const csrfToken = this.getCSRFToken("csrf_refresh_token");
    return { "X-CSRF-TOKEN": csrfToken };
  }

  private getCSRFToken(token: string) {
    let csrfToken = null;
    const cookies = document.cookie.split(";");
    cookies.forEach((cookie) => {
      const [name, value] = cookie.trim().split("=");
      if (name === token) {
        csrfToken = decodeURIComponent(value);
      }
    });
    return csrfToken !== null ? csrfToken : null;
  }

  // auth flow

  async login(username: string, password: string) {
    const loginBody = {
      username: username,
      password: password,
    };
    const data = await apiPostAuthLogin({ body: loginBody });
    if (data) {
      console.log("Logged in");
      await this.checkLoggedIn();
      window.location.href = "/";
    }
  }

  private async setLoggedIn(
    data: UserPatientRoleResponse | UserResponseManaging | FullUserResponse,
  ) {
    this.loggedIn = true;
    this.username = data.username;
    this.userUUID = data.uuid;
    this.userLevel = userLevel[data.role];
    const info = await apiGetInfo();
    this.apiInfo = info.data;
    this.isProdEnv = this.apiInfo.environment === "prod";
    this.amIMonitoringUser = this.userLevel === 4;
    if (data.hasOwnProperty("monitoring_team")) {
      if (data["monitoring_team"] !== null) {
        this.amIMonitoringUser = true;
      }
    }
    this.canIAddPatients = rolesCanAddPatients.includes(data.role);
  }

  async logout() {
    await apiGetAuthLogout();
    console.log("Logged out");
    this.setLoggedOut();
  }

  private setLoggedOut() {
    if (window.location.pathname !== "/login") {
      window.location.href = "/login";
    }
  }

  private async refresh() {
    // This trick with storing the Promise prevents multiple simultaneous calls
    if (!this.refreshPromise) {
      const headers = this.refreshHeaders();
      this.refreshPromise = apiGetAuthRefresh({ headers, throwOnError: false })
        .then((res) => res.response.status === 200)
        .finally(() => {
          this.refreshPromise = undefined;
        });
    }
    return this.refreshPromise;
  }

  private async getMe() {
    const myself = await apiGetUsersMe({ throwOnError: false });
    return myself;
  }

  async checkLoggedIn(tryRefresh: boolean = true) {
    let me = await this.getMe();
    if (me.response.status === 200) {
      await this.setLoggedIn(me.data);
    } else if (me.response.status === 401 && tryRefresh) {
      const refreshSuccess = await this.refresh();
      if (refreshSuccess) {
        let me = await this.getMe();
        if (me.response.status === 200) {
          await this.setLoggedIn(me.data);
          console.log("Token refreshed");
        } else {
          this.setLoggedOut();
        }
      } else {
        this.setLoggedOut();
      }
    }
  }

  // API operations

  async getLimiters() {
    const limiters = await apiGetLimiters();
    return limiters.data;
  }

  async getMonitoringTeams() {
    const teams = await apiGetMonitoringTeams();
    return teams.data;
  }

  async revokePaperConsent(patId: number) {
    await apiPutPatientsPatientIdRevokeConsentPaper({
      path: { patient_id: patId },
    });
    window.location.reload();
  }

  async revokeTeleConsent(patId: number) {
    await apiPutPatientsPatientIdRevokeConsentTelemonitoring({
      path: { patient_id: patId },
    });
    window.location.reload();
  }

  async approvePaperConsent(patId: number) {
    await apiPutPatientsPatientIdApproveConsentPaper({
      path: { patient_id: patId },
    });
    window.location.reload();
  }

  async approveTeleConsent(patId: number) {
    await apiPutPatientsPatientIdApproveConsentTelemonitoring({
      path: { patient_id: patId },
    });
    window.location.reload();
  }

  private interceptRequest = (request: Request) => {
    // Add CSRF token for POST/PUT
    if (
      request.method === "POST" ||
      request.method === "PUT" ||
      request.method === "DELETE"
    ) {
      const token = this.getCSRFToken("csrf_access_token");
      if (token) {
        request.headers.set(
          "X-CSRF-TOKEN",
          this.getCSRFToken("csrf_access_token"),
        );
      }
    }

    // Prepare a clone of the request in case we need to retry it (we cannot clone it after it has been used anymore)
    this.preClonedRequests.set(request, request.clone());

    return request;
  };

  private interceptResponse = async (
    response: Response,
    request: Request,
    options: RequestOptions,
  ) => {
    const clonedRequest = this.preClonedRequests.get(request);
    this.preClonedRequests.delete(request);

    if (response.ok || !options.throwOnError) {
      return response;
    }

    if (response.status === 401) {
      // Try refresh once when we get 401
      const refreshSuccess = await this.refresh();
      return refreshSuccess ? fetch(clonedRequest) : response;
    }

    return response;
  };

  private interceptError = (
    error: unknown,
    response: Response,
    request: Request,
  ) => {
    return new RequestError(error, response, request);
  };
}

export class RequestError extends Error {
  name = "RequestError";

  constructor(
    readonly data: unknown,
    readonly response: Response,
    readonly request: Request,
  ) {
    super(`Status ${response.status} from ${request.method} ${request.url}`);
  }
}

/** This is the type we get from the `/patients` endpoint when we use `load_type: basic`. */
export type PatientDto = Level3PatientResponse | PatientResponse;

export type MedicationDto = DefaultMedicationSchemaResponse;
